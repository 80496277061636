<template>
    <div class="h-full w-full relative"
         :style="`background-color: ${bgColor};`">
        <TheSearchWindow @updateSongs="updateSongs" :songs="songs"/>
        <TheSearchItems :items="songs" />
    </div>
</template>

<script>
import TheSearchWindow from "@/components/TheSearchWindow.vue";
import TheSearchItems from "@/components/TheSearchItems.vue";

export default {
    name: "SearchPage",
    data() {
        return {
            songs: [],
        }
    },
    created() {
        setTimeout(() => window.scrollTo(0, this.$store.getters.getScrollY), 200);
    },
    methods: {
        updateSongs(songs) {
            this.songs = songs;
            this.$store.dispatch('saveSearchItems', songs)
        }
    },
    components: {
        TheSearchItems,
        TheSearchWindow
    },
}
</script>

<style scoped>

</style>