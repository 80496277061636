<template>
    <div class="w-full"
         :style="`background-color: ${bgColor};`">
        <ButtonBack/>
        <div v-if="albumInfo === null" class="unloaded">
            <div role="status" class="animate-pulse flex flex-col items-center justify-center">
                <div class="flex items-center justify-center h-80 bg-gray-300 rounded-xl w-80 ">
                    <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                </div>
                <div class="flex flex-col items-center justify-center mt-8">
                    <div class="h-5 bg-gray-200 rounded-full w-48 mb-4"></div>
                    <div class="h-3 bg-gray-200 rounded-full w-40 mb-2.5"></div>
                </div>
                <span class="sr-only">Loading...</span>
            </div>
            <div class="flex mt-10 ">
                <div class="w-full">
                    <div class="">
                        <div v-for="index in 5" :key="index" role="status"
                             class="border-b border-slate-300 h-24 first:border-t-none
                              p-3 animate-pulse flex items-center justify-center">
                            <div class="w-[5%]">{{ index }}</div>
                            <div class="w-[65%] flex flex-col justify-center">
                                <div class="h-2.5 bg-gray-200 rounded-full w-1/2"></div>
                            </div>
                            <div class="h-full mr-2 mt-2 w-[10%] flex items-center justify-center text-center text-l">
                                <div class="h-2.5 bg-gray-200 rounded-full w-1/2"></div>
                            </div>
                            <div class="h-full mr-2 mt-2 w-[20%]"></div>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="w-full mb-2">
                <div class="flex justify-center">
                    <img :src="albumInfo['artwork_url']" alt="image" class="h-80 rounded-xl">
                </div>
                <div class="flex items-center justify-center h-full mt-8">
                    <div class="h-auto flex items-center justify-center flex-col">
                        <p class="font-semibold text-xl">{{ albumInfo['title'] }}</p>
                    </div>

                    <div class=" p-2 ml-2">
                        <img v-if="playingStatus === 0"
                             class="responsiveIcon cursor-pointer h-14 object-cover"
                             :src="playButton" alt="image"
                             @click="queueTracks(albumInfo['tracks'])">
                        <IconsDownloadingProgress class="m-2" :loadingStatus="playingStatus" />
                    </div>
                </div>
            </div>

            <div  v-for="(track, index) in albumInfo['tracks']"
                  :key="index"
                  class="flex border-b border-slate-300 p-3 items-center h-24 first:border-t first:rounded-t-sm bgColor"

                  :style="`background-color: ${bgColor};`">

    <!--            <div>-->
    <!--                {{ index + 1 }}-->
    <!--            </div>-->
    <!--            <div >-->
    <!--                <p>-->
    <!--                    {{ trackTitle[index] }}-->
    <!--                </p>-->
    <!--                <p>-->

    <!--                </p>-->
    <!--            </div>-->


                <!-- number -->
                <div class="w-[5%] textHintColor">
                    {{ index + 1 }}
                </div>
                <!-- spacer -->
                <div class="w-[2%]"/>
                <!-- title and artist -->
                <div class="flex h-full w-[63%]">
                    <div class="flex flex-col justify-center h-full w-[100%] truncate">
                        <p class="text-lg font-semibold m-0 p-0 truncate text-left">
                            {{ trackTitle[index] }}
                        </p>
                    </div>
                </div>
                <!-- duration -->
                <div class="h-full mr-2 mt-2 w-[10%] flex items-center justify-center text-center text-l">
                    {{ trackDuration[index] }}
                </div>
                <!-- buttons -->
                <div class="h-full flex items-center justify-end w-[20%]">
                    <IconDownload
                        v-if="loadingStatuses[index] === 0"
                        @click="downloadPlaylistTrack(index, track.id)"
                    />
                    <IconsDownloadingProgress :loadingStatus="loadingStatuses[index]"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import addToQueueImage from "@/assets/addToQueue.svg";
import playButton from "@/assets/playButton.svg";
import IconsDownloadingProgress from "@/components/Icons/IconsContainer.vue";


export default {
    name: "PlaylistPage",
    data() {
        return {
            loadingStatuses: [],
            addToQueueImage: addToQueueImage,
            playButton: playButton,
            albumInfo: null,
            playingStatus: 0,
        }
    },
    components: {
        IconsDownloadingProgress
    },
    created() {
        setTimeout(() => window.scrollTo(0, this.$store.getters.getScrollY), 200);
        if (parseInt(this.$store.getters.getPlayListData['id']) === parseInt(this.$route.params.id)) {
            this.albumInfo = this.$store.getters.getPlayListData;
            this.loadingStatuses = new Array(this.albumInfo['tracks'].length).fill(0);
        }
        fetch(`${this.baseUrl}/api/recs/${this.$route.params.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`)
                }
                return response.json()
            })
            .then(data => {
                data = data[0]
                this.$store.dispatch('savePlayListData', data)
                this.albumInfo = data;
                this.loadingStatuses = new Array(this.albumInfo['tracks'].length).fill(0);
            })
            .catch(() => {})
    },
    methods: {
        async downloadPlaylistTrack(index, songId){
            this.loadingStatuses[index] = 2;
            const downloadingResult = await this.downloadTrack(songId);
            if(downloadingResult === 1){
                this.loadingStatuses[index] = -1;
            } else {
                this.loadingStatuses[index] = 1;
            }
            setTimeout(() => this.loadingStatuses[index] = 0, 2000)
        },
    },
    computed: {
        trackDuration() {
            return this.albumInfo['tracks'].map(track => {
                const minutes = Math.floor(track['duration'] / 60000);
                const seconds = Math.floor(track['duration'] / 1000 % 60).toString().padStart(2, '0');
                return `${minutes}:${seconds}`
            })
        },
        trackTitle() {
            return this.albumInfo['tracks'].map(track => track['title'])
        },
    }
}
</script>

<style scoped>

</style>