<template>
    <IconLoading v-if="loadingStatus === 2" :height="height" :width="width" />
    <IconSuccess v-else-if="loadingStatus === 1" :height="height" :width="width" />
    <IconError v-else-if="loadingStatus === -1" :height="height" :width="width" />
</template>

<script>
import IconLoading from "@/components/Icons/IconLoading.vue";
import IconSuccess from "@/components/Icons/IconSuccess.vue";
import IconError from "@/components/Icons/IconError.vue";

export default {
    name: "IconsContainer",
    components: {
        IconLoading,
        IconSuccess,
        IconError
    },
    props: {
        height: {
            type: String,
            default: 'h-10'
        },
        width: {
            type: String,
            default: 'w-10'
        },
        loadingStatus: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>

</style>