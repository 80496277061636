<template>
    <div class="w-11/12 mx-auto h-full relative mt-2">
        <router-view v-slot="{ Component, route }">
            <Transition :name="transitionAnimation()">
                <component :key="route.name" :is="Component"></component>
            </Transition >
        </router-view>
    </div>
</template>

<script>
import store from "@/store";

export default {
    name: 'App',
    created() {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        document.cookie = `tg_data=${JSON.stringify(window.Telegram.WebApp.initDataUnsafe)}`;
    },
    methods: {
        transitionAnimation() {
            // shit
            return store.getters.getSlidingAnimation;
        }
    },
}
</script>

<style scoped>
.slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.2s ease;
    transition-delay: 0s;
}

.slide-right-enter-active, .slide-right-leave-active{
    transition: transform 0.3s ease;
    transition-delay: 0s;
}

.slide-left-leave-active {
    position: absolute;
    z-index: -1;
}

.slide-right-leave-active {
    position: absolute;
    z-index: 1;
}

.slide-left-enter-from {
    transform: translateX(100%);
}

.slide-left-enter-to, .slide-left-leave-from, .slide-left-leave-to {
    transform: translateX(0);
}

.slide-right-leave-from, .slide-right-enter-to, .slide-right-enter-from {
    transform: translateX(0);
}

.slide-right-leave-to {
    transform: translateX(100%);
}

</style>

<style>
#app {
    font-family: "Lucida Grande", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-align: center !important;
    color: #2c3e50 !important;
    /*background: #f4f4f4;*/

}

.responsiveIcon{
    transition: all .2s ease-out;
}
.responsiveIcon:hover{
    transform: scale(.90);
}
.responsiveIcon:active{
    /*border-radius: 100%;*/
    /*background: azure;*/
    transform: scale(.85);
}


.iconPulse {
    animation: icon-pulse-animation 0.5s 1;
}
@keyframes icon-pulse-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}



.bgColor {
    background-color: var(--tg-theme-bg-color);
}
.bgColor:hover {
    background-color: var(--tg-theme-secondary-bg-color);
}

.textButtonColor {
    color: var(--tg-theme-button-color);
}

.textSecBgColor {
    color: var(--tg-theme-secondary-bg-color);
}

.textHintColor {
    color: var(--tg-theme-hint-color);
}

.bgButtonColor {
    background-color: var(--tg-theme-button-color);
}

.borderSecBgColor {
    border-color: var(--tg-theme-secondary-bg-color);
}

</style>
