<template>
    <div class="flex justify-center">
        <div role="status" :class="[width, height]">
            <svg class="iconPulse" fill="#49e994" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 103">
                <path d="M4.43 63.63A14.383 14.383 0 0 1 .003 53.52a14.393 14.393 0 0 1 4.015-10.281 14.372 14.372 0 0 1 10.106-4.425 14.373 14.373 0 0 1 10.283 4.012l24.787 23.851L98.543 3.989l1.768 1.349-1.77-1.355a2.27 2.27 0 0 1 .479-.466A14.383 14.383 0 0 1 109.243.022V.018l.176.016c3.623.24 7.162 1.85 9.775 4.766a14.383 14.383 0 0 1 3.662 10.412h.004l-.016.176a14.362 14.362 0 0 1-4.609 9.632L59.011 97.11l.004.004a2.157 2.157 0 0 1-.372.368 14.392 14.392 0 0 1-9.757 3.569 14.381 14.381 0 0 1-9.741-4.016L4.43 63.63z"/>
            </svg>
            <span class="sr-only">OK</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconSuccess",
    props: {
        height: {
            type: String,
            default: 'h-full'
        },
        width: {
            type: String,
            default: 'w-auto'
        }
    }
}
</script>
