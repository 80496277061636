<template>
    <div class="p-2 flex justify-center items-center cursor-pointer">
        <div role="status" :class="[width, height]">
            <!-- hover:scale-[0.9] transition-all duration-200 -->
            <svg class="responsiveIcon" ref="image" @mouseup="goDown" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                <path :fill="buttonColor" d="m19.35708,0.18548l11.28805,0c2.63855,0 4.76591,2.14464 4.8022,4.8022l0,16.58782l8.23739,0c1.73394,0.07286 2.96525,0.65087 3.67643,1.73141c1.92995,2.89487 -0.70111,5.7521 -2.53301,7.76493c-5.18416,5.69179 -13.94173,14.12266 -16.50241,17.13315c-1.93997,2.14604 -4.69919,2.14604 -6.63916,0c-2.64361,-3.0884 -12.07209,-12.13994 -17.00246,-17.67843c-1.7138,-1.92742 -3.82721,-4.55089 -2.04551,-7.21965c0.72874,-1.08054 1.94751,-1.65855 3.68144,-1.73141l8.23486,0l0,-16.58782c0,-2.626 2.16365,-4.8022 4.8022,-4.8022l0,0l-0.00005,0z"/>
            </svg>
            <span class="sr-only">Download</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconDownload",
    props: {
        height: {
            type: String,
            default: 'h-10'
        },
        width: {
            type: String,
            default: 'w-10'
        }
    },
    methods: {
        // goDown(){
        //     this.$refs.image.classList.add('startDownloading')
        //     setTimeout(() => {
        //         this.$refs.image.classList.remove('startDownloading')
        //     }, 500);
        // }
    }
}
</script>

<style scoped>
.startDownloading {
    transform-origin: top;
    animation: start-downloading-animation 0.5s 1;
}


@keyframes start-downloading-animation {
    0% {
        transform: scaleY(0.7) scaleX(1);
    }
    50%{
        transform: scaleY(1.2) scaleX(0.8) translateY(-7%);
    }
    100% {
        transform: scaleY(1) scaleX(1) translateY(0);
    }
}
</style>