<template>
    <label
        class="z-50 h-20 block rounded-t-3xl rounded-b-sm text-lg font-medium focus-within:ring-[3px] searcher"
        :class="[songs.length === 0 && (queries.length === 0 || !outlined) ? 'rounded-b-3xl' : '']"
        :style="'caret-color:' + buttonColor + '; --tw-ring-color: ' + buttonColor"
    >
        <IconSearch :color="outlined ? buttonColor : '#6b7280'"/>
        <input
               type="search"
               class="pl-10 pr-3 w-full h-full text-lg font-medium outline-none bg-transparent rounded-t-3xl"
               placeholder="Search"
               v-model="current_query"
               @input="changeText"
               @keyup.enter="getMusic(current_query)"
               @focus="outlined = true"
               @blur="outlined = false"
               id="inp"
        >
    </label>
    <div class="w-full flex justify-center mb-2">
        <div :class="[queries.length === 0 || !outlined ? 'hidden' : '', queries.length !== 0 && outlined ? 'ring-[3px]': '']"
             :style="'background-color: ' + bgColor + '; --tw-ring-color: ' + buttonColor"
             class="w-full mt-1 shadow dropdown rounded-b-3xl divide-y divide-gray-200 m-auto hover:block solid border-1 absolute z-50">  <!-- relative -->
            <div v-for="(qr, ind) in queries" :key="ind"
                 class="dropdown-menu last:rounded-b-3xl bgColor">
                <button @click="getMusic(qr)"
                        class="text-left w-full block py-2 px-4">{{ qr }}</button>
            </div>
        </div>
    </div>

    <IconLoading v-if="songs.length === 0" height="h-10" width="w-10"/>
</template>

<script>
import IconSearch from '@/components/Icons/IconSearch.vue'
import IconLoading from '@/components/Icons/IconLoading.vue'

export default {
    name: "TheSearchWindow.vue",
    emits: ["updateSongs"],
    props: {
        songs: {
            type: Array,
            default: () => []
        },
    },
    components: {
        IconSearch, IconLoading
    },
    data(){
        return{
            queries: [],
            current_query: undefined,
            outlined: false,
        }
    },
    methods: {
        getRecommendations: function(){
            fetch(`${this.baseUrl}/api/recs/579673896`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    return response.json()
                })
                .then(data => {
                    this.$emit('updateSongs', data);
                })
                .catch(error => console.log(error))
        },
        getCharts: function() {
            fetch(`${this.baseUrl}/api/charts`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    return response.json()
                })
                .then(data => {
                    this.$emit('updateSongs', data);
                })
                .catch(error => console.log(error))
        },
        getMusic(music_name) {
            document.activeElement.blur();
            this.current_query = music_name;
            this.$router.push({ path: '/', query: { search_query: music_name }})
            this.queries = [];
            this.$emit('updateSongs', []);
            fetch(`${this.baseUrl}/api/get_music?song_name=${music_name}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    return response.json()
                })
                .then(data => {
                    this.$emit('updateSongs', data);
                })
                .catch(error => console.log(error))
        },
        changeText(event){
            this.$router.push({ path: '/', query: { search_query: event.target.value }})
            fetch(`${this.baseUrl}/api/query?search_query=${event.target.value}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    return response.json()
                })
                .then(data => {
                    this.queries = data;
                })
                .catch(error => console.log(error))
        },
    },
    created() {
        this.current_query = this.$route.query.search_query;
        if (this.$store.getters.getSearchItems.length !== 0) {
            this.$emit('updateSongs', this.$store.getters.getSearchItems);
        } else if (this.current_query !== undefined && this.current_query !== '') {
            this.getMusic(this.current_query)
        } else {
            this.getRecommendations()
        }


    },
}
</script>

<style scoped>

.searcher {
    background-color: var(--tg-theme-secondary-bg-color);
}

.searcher:focus-within {
    background-color: var(--tg-theme-bg-color);
}

</style>