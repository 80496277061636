import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import IconsDownloadingProgress from "@/components/Icons/IconsContainer.vue";
import ButtonBack from "@/components/Icons/ButtonBack.vue";
import IconDownload from "@/components/Icons/IconDownload.vue";
import store from './store'


const app = createApp(App).use(router).use(store).mixin({
    data: function() {
        return {
            bgColor: window.Telegram.WebApp.themeParams.bg_color || '#FFF',
            secBgColor: window.Telegram.WebApp.themeParams.secondary_bg_color || '#f1f1f1',
            buttonColor: window.Telegram.WebApp.themeParams.button_color || '#40a7e3',
            textColor: window.Telegram.WebApp.themeParams.text_color || '#000',
            hintColor: window.Telegram.WebApp.themeParams.hint_color || '#999',
            baseUrl: ''
        }
    },
    methods: {
        async queueTracks(tracks, sort = false) {
            this.playingStatus = 2;
            let tracks_sorted = tracks;
            if (sort) {
                tracks_sorted = [...tracks_sorted].sort(function(a, b){
                    return a['playback_count'] > b['playback_count'] ? -1 : a['playback_count'] < b['playback_count'] ? 1 : 0;
                });
            }
            let params = new URLSearchParams();
            params.append('track_ids', tracks_sorted.map(track => track['id']).join(','));
            await fetch(`${this.baseUrl}/api/play_tracks?${params.toString()}`)
            this.playingStatus = 1;
            setTimeout(() => this.playingStatus = 0, 2000)
        },
        async downloadTrack(eid){
            this.loadingStatus = 2;
            let error = 0;
            await fetch(`${this.baseUrl}/api/track/${eid}/load`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    this.loadingStatus = 1;
                    return response.json()
                })
                .catch(() => {
                    error = 1;
                    this.loadingStatus = -1;
                }).finally(() => {
                setTimeout(() => this.loadingStatus = 0, 2000)
            })
            setTimeout(() => this.loadingStatus = 0, 2000)
            return error;
        },
    }
})

app.component('IconsDownloadingProgress', IconsDownloadingProgress)
    .component('ButtonBack', ButtonBack)
    .component('IconDownload', IconDownload)

app.mount('#app')