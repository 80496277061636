<template>
    <div class="h-14 w-[20%] flex items-center justify-center">
        <div class="relative h-full text-center">
            <img :src="album['artwork_url']" alt="image"
                 class="rounded-lg object-scale-down max-h-full relative z-20 max-w-full">
            <img :src="album['artwork_url']" alt="image"
                 class="max-h-full max-w-full top-0.5 left-0.5 opacity-60 absolute rounded-lg z-10">
            <img :src="album['artwork_url']" alt="image"
                 class="max-h-full max-w-full top-1 left-1 opacity-50 absolute rounded-lg z-0">
        </div>
    </div>
    <div class="flex items-center justify-between w-[80%]">
        <!-- title and artist -->
        <div class="flex flex-col justify-center h-full w-[70%] truncate text-left">
            <p class="text-base font-semibold truncate ">
                {{ albumTitle }}
            </p>
            <div class="flex text-sm textHintColor space-x-2">
                <p class="truncate"> {{ album.user.username }} </p>
                <span>&#x2022;</span>
                <p> {{ album['tracks'].length }} tracks </p>
            </div>
        </div>
        <!-- buttons -->
        <div class="h-full flex justify-center w-[30%] grid grid-rows-2">
            <div @click="redirectUser()" class="cursor-pointer ">
                <img class="p-1 responsiveIcon"
                     :src="infoImage" alt="image">
            </div>
            <div class="cursor-pointer flex justify-center items-center">
                <IconDownload
                    v-if="loadingStatus === 0"
                    @click="downloadAlbum(album.id)"
                />
                <IconsDownloadingProgress :loadingStatus="loadingStatus"/>
            </div>
        </div>
    </div>
</template>

<script>
import infoImage from "@/assets/info.svg";


export default {
    name: "SearchAlbumCard",
    data() {
        return {
            loadingStatus: 0,

            infoImage: infoImage
        }
    },
    props: {
        album: JSON
    },
    methods: {
        downloadAlbum (eid){
            this.loadingStatus = 2;
            fetch(`${this.baseUrl}/api/playlist/${eid}/load`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`)
                    }
                    this.loadingStatus = 1;
                    return response.json()
                })
                .catch(() => {
                    this.loadingStatus = -1;
                }).finally(() => {
                    setTimeout(() => this.loadingStatus = 0, 2000)
                })
        },
        redirectUser () {
            if (this.$store.getters.getPlayListData['id'] === this.album['id'])
                return this.$router.push('/album/' + this.album['id'])
            let data = {...this.album};
            data['id'] = -1;
            this.$store.dispatch('savePlayListData', data)
            this.$router.push('/album/' + this.album['id'])
        }
    },
    computed: {
        albumTitle() {
            return this.album.title.split(' - ')[this.album.title.split(' - ').length - 1]
        }
    }
}
</script>

<style scoped>

</style>