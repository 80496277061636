import {createStore} from "vuex";

const store = createStore({
    state: {
        searchItems: [],
        artistData: {},
        playListData: {},
        pagesStack: [],
        slidingAnimation: '',
        scrollY: 0,
    },
    mutations: {
        setSearchItems(state, data) {
            state.searchItems = data
        },
        setArtistData(state, data) {
            state.artistData = data
        },
        setPlayListData(state, data) {
            state.playListData = data
        },
        pushPage(state, data ) {
            state.pagesStack.push({'name': data, 'scroll': window.scrollY})
        },
        popPage(state) {
            state.pagesStack.pop()
        },
        setSlidingAnimation(state, data) {
            state.slidingAnimation = data
        },
        setScrollY(state, data) {
            state.scrollY = data
        }
        // setArtistScroll(state, data) {
        //     state.artist.scroll = data
        // },
        // setArtistPage
    },
    actions: {
        saveSearchItems({ commit }, data) {
            commit('setSearchItems', data)
        },
        saveArtistData({ commit }, data) {
            commit('setArtistData', data)
        },
        savePlayListData({ commit }, data) {
            commit('setPlayListData', data)
        },
        pushPage({ commit }, data) {
            commit('pushPage', data)
        },
        popPage({ commit }) {
            commit('popPage')
        },
        setSlidingAnimation({ commit }, data) {
            commit('setSlidingAnimation', data)
        },
        setScrollY({ commit }, data) {
            commit('setScrollY', data)
        }
        // setArtistScroll({ commit }, data) {
        //     commit('setArtistScroll', data)
        // }
    },
    getters: {
        getSearchItems(state) {
            return state.searchItems
        },
        getArtistData(state) {
            return state.artistData
        },
        getPlayListData(state) {
            return state.playListData
        },
        getTopPage(state) {
            if (state.pagesStack.length === 0)
                return undefined;
            return state.pagesStack[state.pagesStack.length - 1]
        },
        getSlidingAnimation(state) {
            return state.slidingAnimation
        },
        getScrollY(state) {
            return state.scrollY
        }
    }
})

export default store