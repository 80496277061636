<template>
    <div class="h-12 w-[20%] flex items-center justify-center">
        <img class="rounded-lg object-scale-down h-full w-auto"
             :src="song['artwork_url']" alt="image">
    </div>
    <div class="flex items-center justify-between w-[80%]">
        <div class="w-[70%] h-full truncate text-left">
            <p class="text-base font-semibold truncate ">
                {{ trackTitle }}
            </p>
            <div class="flex text-sm textHintColor space-x-2">
                <p> {{ trackDuration }} </p>
                <span> &#x2022; </span>
                <p class="truncate"> {{ song.user.username }} </p>
            </div>
        </div>
        <!-- buttons -->
        <div class="flex justify-center w-[30%]">
            <IconDownload
                v-if="loadingStatus === 0"
                @click="downloadTrack(song.id)"
            />
            <IconsDownloadingProgress :loading-status="loadingStatus"/>
        </div>
    </div>
</template>

<script>
import addToQueueImage from "@/assets/addToQueue.svg";
import infoImage from "@/assets/info.svg";


export default {
    name: 'SearchTrackCard',
    data() {
        return {
            addToQueueImage: addToQueueImage,
            infoImage: infoImage,
            loadingStatus: 0
        }
    },
    props: {
        song: JSON
    },
    computed: {
        trackTitle() {
            return this.song.title.split(' - ')[this.song.title.split(' - ').length - 1];
        },
        trackDuration() {
            return Math.floor(this.song.duration / 60000) + ':' + Math.floor(this.song.duration / 1000 % 60).toString().padStart(2, '0');
        },
    }
}
</script>

<style scoped>
</style>