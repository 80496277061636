<template>
    <div class="h-14 mr-2 w-[20%] flex items-center justify-center">
        <div class="relative h-full w-auto text-center">
            <img :src="playlist['artwork_url']" alt="artwork"
                 class="rounded-lg object-scale-down max-h-full relative z-20 max-w-full">
        </div>
    </div>
    <!-- title and artist -->
    <div class="flex h-full items-center w-[60%]">
        <div class="flex flex-col justify-center h-full w-full truncate">
            <p class="text-base font-semibold m-0 p-0 truncate">
                {{ playlist.title }}
            </p>
        </div>
<!--        <ul-->
<!--            class="items-center h-full text-sm w-[50%] truncate">-->
<!--            <li :class="[`text-[${textColor}]`]" class="truncate" v-for="(track, i) in playlist['tracks']" :key="i">  &lt;!&ndash; TODO show related artists &ndash;&gt;-->
<!--                <div v-if="track.title">{{track.title}}</div></li>-->
<!--            <li class="mt-0.5 text-[{{hintColor}}] truncate">total {{playlist['tracks'].length}} tracks</li>-->
<!--        </ul>-->
    </div>
    <!-- buttons -->
    <div class="h-full flex items-center justify-center ml-auto w-[20%] flex-col">
        <router-link :to="'/playlist/' + playlist.id" class="cursor-pointer h-1/2 w-full">
            <img class="p-2 responsiveIcon" :src="infoImage" alt="info">
        </router-link>
    </div>
</template>


<script>
import infoImage from "@/assets/info.svg";

export default {
    name: "SearchPlaylistCard",
    data() {
        return {
            infoImage: infoImage,
        }
    },
    props: {
        playlist: JSON
    },
    computed: {

    }
}
</script>

<style scoped>

</style>