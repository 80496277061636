<template>
    <div class="h-12 w-[20%] flex items-center justify-center">
        <img class="rounded-full object-scale-down h-full w-auto"
             :src="artist['avatar_url']" alt="image">
    </div>
    <div class="flex items-center justify-between w-[80%]">
        <div class="w-[70%] h-full truncate text-left">
            <p class="text-base truncate font-bold ">
                {{ artist['username'] }}
            </p>
            <p class="text-sm textHintColor">
                {{ followersCount }} followers
            </p>
        </div>


        <!-- buttons -->
        <div class="flex justify-center w-[30%] cursor-pointer" @click="redirectUser()">
            <img class="p-2 h-16 w-auto responsiveIcon"
                 :src="infoImage" alt="image">
        </div>
    </div>
</template>

<script>
import infoImage from "@/assets/info.svg";

export default {
    name: "SearchArtistCard",
    data() {
        return {
            infoImage: infoImage
        }
    },
    props: {
        artist: JSON
    },
    methods: {
        redirectUser () {
            if (this.$store.getters.getArtistData['id'] === this.artist['id']) {
                let data = {...this.$store.getters.getArtistData};
                data['page'] = 0;
                this.$store.dispatch('saveArtistData', data)
                return this.$router.push('/artist/' + this.artist['id'])
            }
            let data = {...this.artist};
            data['id'] = -1;
            this.$store.dispatch('saveArtistData', data)
            this.$router.push('/artist/' + this.artist['id'])
        }
    },
    computed: {
        followersCount() {
            if (this.artist['followers_count'] < 1000)
                return this.artist['followers_count'];
            if (this.artist['followers_count'] < 1000000)
                return Math.round(this.artist['followers_count'] / 100) / 10 + 'k';
            return Math.round(this.artist['followers_count'] / 100000) / 10 + 'm';
        },
    }
}
</script>

<style scoped>

</style>