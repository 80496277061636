<template>
    <div class="flex justify-center">
        <div role="status" :class="[width, height]">
            <svg class="iconPulse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123">
                <path fill="#ff4141" d="M6 6a20.53 20.53 0 0 1 29 0l26.5 26.49L87.93 6a20.54 20.54 0 0 1 29 0 20.53 20.53 0 0 1 0 29L90.41 61.44l26.49 26.49a20.54 20.54 0 0 1 0 29 20.54 20.54 0 0 1-29 0L61.44 90.41 35 116.9a20.54 20.54 0 0 1-29 0 20.54 20.54 0 0 1 0-29l26.47-26.46L6 34.94A20.53 20.53 0 0 1 6 6Z"/>
            </svg>
            <span class="sr-only">OK</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorIcon",
    props: {
        height: {
            type: String,
            default: 'h-full'
        },
        width: {
            type: String,
            default: 'w-auto'
        }
    },
}
</script>