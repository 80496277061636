import { createRouter, createWebHistory } from 'vue-router'
import SearchPage from "@/views/SearchPage";
import ArtistPage from "@/views/ArtistPage";
import AlbumPage from "@/views/AlbumPage.vue";
import PlaylistPage from "@/views/PlaylistPage.vue";
import store from "@/store";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: SearchPage,
        meta: {

        },
    },
    {
        path: '/album/:id',
        name: 'album',
        component: AlbumPage,
        meta: {

        },
    },
    {
        path: '/artist/:id',
        name: 'artist',
        component: ArtistPage,
        meta: {

        },
    },
    {
        path: '/playlist/:id',
        name: 'playlist',
        component: PlaylistPage,
        meta: {

        },
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    // scrollBehavior(to, from, savedPosition) {
    //     // window.scrollTo({ top: 0 });
    //     // window.scrollTo(0, store.getters.getScrollY);
    //     return new Promise((resolve) => {
    //         console.log(savedPosition)
    //         if (savedPosition) {
    //             resolve(savedPosition);
    //         } else {
    //             setTimeout(() => {
    //                 console.log('scroll to top')
    //                 resolve({top: 0});
    //             }, 200);
    //         }
    //     })
    //     // if (savedPosition) {
    //     //     return savedPosition;
    //     // } else {
    //     //     return {top: 0};
    //     // }
    // },
})

router.beforeEach((to, from, next) => {
    // shit garbage trash, but it works, I create some kind of stack for pages history
    if (store.getters.getTopPage === undefined) {
        store.commit('setSlidingAnimation', '');
        store.commit('setScrollY', 0)
        store.commit('pushPage', to.name);
    }else if (store.getters.getTopPage['name'] === to.name) {
        store.commit('setSlidingAnimation', 'slide-right');
        store.commit('setScrollY', store.getters.getTopPage['scroll'])
        store.commit('popPage');
    } else {
        store.commit('setSlidingAnimation', 'slide-left');
        store.commit('setScrollY', 0)
        store.commit('pushPage', from.name);
    }
    // console.log(from, to, store.getters.getSlidingAnimation)
    next();
});

export default router
