<template>
    <div v-for="(item, index) in items" :key="index" class="first:border-t first:rounded-t-sm w-full h-fit bgColor">
        <div class="h-28 w-full px-1 flex items-center space-x-2">
            <TrackCard v-if="item['kind'] === 'track'" :song="item"/>
            <AlbumCard v-else-if="item['kind'] === 'playlist'" :album="item"/>
            <ArtistCard v-else-if="item['kind'] === 'user'" :artist="item"/>
            <PlaylistCard v-else-if="item['kind'] === 'recommendations'" :playlist="item"/>
        </div>
        <div class="w-[80%] border-b border-slate-300 ml-auto"></div>
    </div>
</template>

<script>
import TrackCard from "@/components/SearchTrackCard.vue";
import AlbumCard from "@/components/SearchAlbumCard.vue";
import ArtistCard from "@/components/SearchArtistCard.vue";
import PlaylistCard from "@/components/SearchPlaylistCard.vue";

export default {
    name: 'TheSearchItems',
    data(){
        return{
            // items: JSON,
        }
    },
    components: {
        TrackCard,
        AlbumCard,
        ArtistCard,
        PlaylistCard
    },
    props: {
        items: JSON
    },
}
</script>


<style scoped>

</style>
