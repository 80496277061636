<template>
    <div class="w-full h-full" :style="'background-color: ' + bgColor">
        <ButtonBack/>

        <div class="space-y-3 w-full" :style="'background-color: ' + bgColor">
            <!-- artist info image, followers, name, etc -->
            <div class="flex flex-col items-center">
                <div v-if="artist['avatar_url'] === undefined" class="flex bg-gray-300 items-center justify-center w-24 h-24 rounded-full animate-pulse">
                    <svg class="w-12 h-12 text-gray-200 " xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                </div>
                <img v-else :src="artist['avatar_url']" alt="artist image" class="rounded-full w-1/4 h-auto">
            </div>

            <div
                ref="artistHeader"
                class="flex flex-col items-center justify-center w-full h-12 sticky top-0 transition-all duration-300 z-20"
                :style="'background-color: ' + bgColor"
            >
                <div v-if="artist['username'] === undefined" class="h-3.5 bg-gray-300 rounded-full w-1/2 animate-pulse mb-2"></div>
                <div v-else ref="artistName" class="transition-all duration-300 text-3xl font-medium">
                    {{ artist['username'] }}
                </div>

                <div v-if="artist['followers_count'] === undefined" class="h-3 bg-gray-200 rounded-full w-5/12 animate-pulse"></div>
                <div v-else ref="artistFollowers" class="transition-all duration-200 textHintColor">
<!--                    <Transition v-if="artist['singles'] !== undefined" :name="transitionName + '-header'">-->
                    <span v-if="lastScrollY <= 170">
                        {{ followersCount }} followers
                    </span>
                    <div v-else-if="artist['id'] !== -1">
                        <span v-if="activeScene === 0">
                            {{ artist['tracks'].length }} tracks
                        </span>
                        <span v-else-if="activeScene === 1" >
                            {{ artist['singles'].length }} singles
                        </span>
                        <span v-else-if="activeScene === 2">
                            {{ artist['albums'].length }} albums
                        </span>
                    </div>
<!--                    </Transition>-->
                </div>
            </div>

            <!-- buttons (play/info etc) -->
            <div class="flex justify-center w-full space-x-2 bg-#f1f1f1">
                <div class="w-16 h-14 rounded-lg flex items-center justify-between flex-col py-2 border cursor-pointer bgColor textButtonColor"
                     @click="queueTracks(artist['tracks'])">
                    <div v-if="playingStatus === 0" class="w-7/12 h-1/2 flex justify-center">
                        <svg :fill="buttonColor" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                            <path d="m11.62678,2.75082c-4.19887,-2.40818 -7.59977,-0.43699 -7.59977,4.40312l0,35.69042c0,4.8401 3.4009,6.81129 7.59977,4.40787l31.19705,-17.88796c4.19887,-2.40818 4.19887,-6.30781 0,-8.71599l-31.19705,-17.89746z"/>
                        </svg>
                    </div>
                    <span v-if="playingStatus === 0">play</span>
                    <div class="h-full w-full flex items-center justify-center">
                        <IconsDownloadingProgress height="h-5" width="w-5" :loadingStatus="playingStatus" />
                    </div>
                </div>
            </div>

            <!-- scene buttons -->
            <div ref="tabs"
                 class="flex justify-around font-medium h-10 border-b w-full sticky top-12 bg-white transition-all duration-300 z-10 transition-transform textHintColor borderSecBgColor">
                <div @click="changeSlide(0)"
                     class="flex justify-center w-full"
                     :style="[activeScene === 0 ? `color: ${buttonColor}` : '']">
                    <div class="flex flex-col justify-between w-fit h-full hover:cursor-pointer">
                        Feed
                        <div v-show="activeScene === 0"
                             class="w-full h-1 rounded-t-3xl"
                             :style="'background-color: ' + buttonColor">
                        </div>
                    </div>
                </div>
                <div @click="changeSlide(1)"
                     class="flex justify-center w-full"
                     :class="[activeScene === 1 ? 'textButtonColor' : '']">
                    <div class="flex flex-col justify-between w-fit h-full hover:cursor-pointer">
                        Singles
                        <div v-show="activeScene === 1"
                             class="w-full h-1 rounded-t-3xl"
                             :style="'background-color: ' + buttonColor">
                        </div>
                    </div>
                </div>
                <div @click="changeSlide(2)"
                     class="flex justify-center w-full"
                     :class="[activeScene === 2 ? 'textButtonColor' : '']">
                    <div class="flex flex-col justify-between w-fit h-full hover:cursor-pointer">
                        Albums
                        <div v-show="activeScene === 2"
                             class="w-full h-1 rounded-t-3xl bgButtonColor">
                        </div>
                    </div>
                </div>
            </div>

            <!-- scene content (feed/tracks/albums) -->
            <Transition :name="transitionName"
                        @touchstart="onTouchStart"
                        @touchmove="onTouchMove"
                        @touchend="onTouchEnd" >
                <div ref="data" v-if="activeScene === 0" class="w-full" :style="'background-color: ' + bgColor">
                    <TrackCard v-for="(track, index) in artist['tracks'] ?? [null, null, null, null, null]" :key="index" :track="track"></TrackCard>
                </div>
                <div ref="data" v-else-if="activeScene === 1" class="w-full" :style="'background-color: ' + bgColor">
                    <TrackCard v-for="(single, index) in artist['singles'] ?? [null, null, null, null, null]" :key="index" :track="single"></TrackCard>
                </div>
                <div ref="data" v-else-if="activeScene === 2" class="w-full grid grid-cols-2" :style="'background-color: ' + bgColor">
                    <AlbumCard v-for="(album, index) in artist['albums'] ?? [null, null, null, null, null]" :key="index" :album="album"></AlbumCard>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import playButton from "@/assets/playButton.svg";
import AlbumCard from "@/components/ArtistAlbumCard.vue";
import TrackCard from "@/components/ArtistPopularTrackCard.vue";

export default {
    name: "ArtistPage",
    components: {
        TrackCard,
        AlbumCard,
    },
    data(){
        return{
            playButton: playButton,
            artist: JSON,
            playingStatus: 0,
            activeScene: 0,
            lastScrollY: window.scrollY,
            transitionName: '',

            startX: 0,
            currentX: 0
        }
    },
    created() {
        window.addEventListener('scroll', this.scrollListener);
        setTimeout(() => window.scrollTo(0, this.$store.getters.getScrollY), 160);
        this.artist = this.$store.getters.getArtistData;
        if (parseInt(this.artist['id']) === parseInt(this.$route.params.id)) {
            this.activeScene = this.artist['page'];
            return;
        }
        fetch(`${this.baseUrl}/api/artist/${this.$route.params.id}/info`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`)
                }
                return response.json()
            })
            .then(data => {
                this.$store.dispatch('saveArtistData', data)
                this.artist = data;
                let album_tracks = this.artist['albums'].map(album => album['tracks'].map(track => track['id']));
                album_tracks = album_tracks.flat()
                this.artist['singles'] = this.artist['tracks'].filter(track => !album_tracks.includes(track['id']));
                this.headerText = this.artist['followers_count'] + ' followers';
            })
            .catch(() => {
                // some problem with the request
            })
    },
    unmounted () {
        window.removeEventListener('scroll', this.scrollListener);
    },
    methods: {
        scrollListener(){
            if(this.artist === null) return;
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.$refs.artistName.style.fontSize = "1.3rem";
                this.$refs.artistName.style.lineHeight = "1.3rem";
                this.$refs.artistFollowers.style.fontSize = "0.875rem";
                this.$refs.artistFollowers.style.lineHeight = "1.25rem";
            } else {
                this.$refs.artistName.style.fontSize = "1.875rem";
                this.$refs.artistName.style.lineHeight = "2.25rem";
                this.$refs.artistFollowers.style.fontSize = "1rem";
                this.$refs.artistFollowers.style.lineHeight = "1.5rem";
            }
            if((document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) && document.body.scrollTop < 170 && document.documentElement.scrollTop < 170){
               this.$refs.artistHeader.style.borderBottom = "1px solid #e5e5e5";
            } else {
                this.$refs.artistHeader.style.borderBottom = "0 solid transparent";
            }

            if ((document.body.scrollTop > 220 || document.documentElement.scrollTop > 220) && this.lastScrollY < window.scrollY) {
                this.$refs.tabs.classList.add("tabHidden");
            } else {
                this.$refs.tabs.classList.remove("tabHidden");
            }
            this.lastScrollY = window.scrollY;
            this.artist['scrollY'] = window.scrollY;
            this.$store.dispatch('saveArtistData', this.artist)
        },
        changeSlide(index){
            if(this.activeScene > index)
                this.transitionName = 'slide-right';
            else
                this.transitionName = 'slide-left';
            this.activeScene = index;
            window.scrollTo({
                top: 200,
                left: 0,
                behavior: document.body.scrollTop < 100 && document.documentElement.scrollTop < 100 ? 'smooth' : 'auto'
            });
            this.artist['page'] = index;
            this.$store.dispatch('saveArtistData', this.artist)
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX
        },
        onTouchMove(e) {
            this.currentX = e.touches[0].clientX
        },
        onTouchEnd() {
            if (this.currentX - this.startX > 50 && this.activeScene > 0) {
                this.changeSlide(this.activeScene - 1)
            } else if (this.currentX - this.startX < -50 && this.activeScene < 2) {
                this.changeSlide(this.activeScene + 1)
            } else {  // unused
                this.$refs.data.style.transform = `translateX(0)`
            }
        },
    },
    computed: {
        followersCount() {
            return this.artist['followers_count'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>

<style scoped>
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
    transition: transform 0.2s ease;
    transition-delay: 0s;
}

.slide-left-leave-active, .slide-right-leave-active {
    position: absolute;
}

.slide-left-enter-from, .slide-right-leave-to {
    transform: translateX(100%);
}

.slide-left-leave-to, .slide-right-enter-from {
    transform: translateX(-100%);
}

.slide-left-enter-to, .slide-left-leave-from, .slide-right-enter-to, .slide-right-leave-from {
    transform: translateX(0);
}


.tabHidden {
    transform: translateY(-40px);
    box-shadow: none;
}
</style>