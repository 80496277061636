<template>
    <div class="w-full h-full"
         :style="'background-color: ' + bgColor">
        <ButtonBack/>
        <div class="flex justify-center w-full h-64">
            <div v-if="albumInfo['artwork_url'] === undefined" class="flex items-center justify-center h-64 bg-gray-300 rounded-xl w-64 animate-pulse">
                <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
            </div>
            <img v-else :src="albumInfo['artwork_url']" alt="image" class="h-64 rounded-xl">
        </div>
        <div class="flex flex-col items-center justify-center h-full mt-8 w-full">
            <div class="h-10 flex items-center justify-center flex-col w-full sticky top-0 z-10"
                 :style="'background-color: ' + bgColor">
                <div v-if="albumInfo['title'] === undefined" class="h-5 bg-gray-200 rounded-full w-48 mb-4 animate-pulse"></div>
                <p v-else ref="albumName" class="transition-all duration-300 font-semibold text-xl">{{ albumInfo['title'] }}</p>
                <div v-if="albumInfo['user'] === undefined" class="h-3 bg-gray-200 rounded-full w-40 mb-2.5 animate-pulse"></div>
                <p v-else ref="artistName" class="transition-all duration-300 text-sm font-medium textHintColor">{{  albumInfo.user.username }}</p>
            </div>
            <div @click="queueTracks(albumInfo['tracks'])"
                 class="w-16 h-14 rounded-lg flex items-center justify-between flex-col py-2 border cursor-pointer mt-2 bgColor textButtonColor">
                <div v-if="playingStatus === 0" class="w-7/12 h-1/2 flex justify-center">
                    <svg :fill="buttonColor" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                        <path d="m11.62678,2.75082c-4.19887,-2.40818 -7.59977,-0.43699 -7.59977,4.40312l0,35.69042c0,4.8401 3.4009,6.81129 7.59977,4.40787l31.19705,-17.88796c4.19887,-2.40818 4.19887,-6.30781 0,-8.71599l-31.19705,-17.89746z"/>
                    </svg>
                </div>
                <span v-if="playingStatus === 0">play</span>
                <div class="h-full w-full flex items-center justify-center">
                    <IconsDownloadingProgress height="h-5" width="w-5" :loadingStatus="playingStatus" />
                </div>
            </div>

            <div v-for="(track, index) in albumInfo['tracks'] ?? [null, null, null, null, null]" :key="index"
                 class="cursor-pointer w-full bgColor"
                 @click="downloadPlaylistTrack(index, track.id)">
                <div class="p-3 h-16 w-full flex items-center">
                    <div class="w-[5%] textHintColor">
                        {{ index + 1 }}
                    </div>
                    <!-- spacer -->
                    <div class="w-[2%]"/>
                    <!-- title and artist -->
                    <div class="flex flex-col justify-center h-full w-[63%] truncate">
                        <div v-if="track === null" class="h-2.5 bg-gray-200 rounded-full w-1/2 animate-pulse"></div>
                        <p v-else class="text-base font-medium m-0 p-0 truncate text-left">
                            {{ trackTitle[index] }}
                        </p>
                    </div>
                    <!-- duration -->
                    <div class="h-full mr-2 mt-2 w-[30%] flex items-center justify-end text-l">
                        <div v-if="track === null" class="h-2.5 bg-gray-200 rounded-full w-1/3 animate-pulse"></div>
                        <p v-else>
                            {{ trackDuration[index] }}
                        </p>
                    </div>
                    <!-- buttons -->
                    <IconsDownloadingProgress height="h-5" width="w-5" :loadingStatus="loadingStatuses[index]" />
                </div>
                <div class="w-[90%] border-b border-slate-300 ml-auto"></div>
            </div>

        </div>
    </div>
</template>

<script>
import addToQueueImage from "@/assets/addToQueue.svg";
import playButton from "@/assets/playButton.svg";


export default {
    name: "AlbumPage",
    data() {
        return {
            loadingStatuses: [],
            addToQueueImage: addToQueueImage,
            playButton: playButton,
            albumInfo: JSON,
            playingStatus: 0,
        }
    },
    created() {
        setTimeout(() => window.scrollTo(0, this.$store.getters.getScrollY), 150);
        this.albumInfo = this.$store.getters.getPlayListData;
        console.log(this.albumInfo)
        if (parseInt(this.$store.getters.getPlayListData['id']) === parseInt(this.$route.params.id)) {
            this.albumInfo = this.$store.getters.getPlayListData;
            this.loadingStatuses = new Array(this.albumInfo['tracks'].length).fill(0);
        }
        fetch(`${this.baseUrl}/api/playlist/${this.$route.params.id}/info`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Request failed with status ${response.status}`)
                }
                return response.json()
            })
            .then(data => {
                this.$store.dispatch('savePlayListData', data)
                this.albumInfo = data;
                this.loadingStatuses = new Array(this.albumInfo['tracks'].length).fill(0);
            })
            .catch(() => {})
        window.addEventListener('scroll', this.scrollListener);
    },
    unmounted () {
        window.removeEventListener('scroll', this.scrollListener);
    },
    methods: {
        scrollListener(){
            if(this.albumInfo === undefined) return;

            if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                this.$refs.albumName.classList.remove('font-semibold')
                this.$refs.albumName.style.fontSize = "1rem";
                this.$refs.albumName.style.lineHeight = "1.2rem";
                this.$refs.artistName.style.fontSize = "0.75rem";
                this.$refs.artistName.style.lineHeight = "1rem";
            } else {
                this.$refs.albumName.classList.add('font-semibold')
                this.$refs.albumName.style.fontSize = "1.25rem";
                this.$refs.albumName.style.lineHeight = "1.75rem";
                this.$refs.artistName.style.fontSize = "0.875rem";
                this.$refs.artistName.style.lineHeight = "1.25rem";
            }

        },
        async downloadPlaylistTrack(index, songId){
            this.loadingStatuses[index] = 2;
            const downloadingResult = await this.downloadTrack(songId);
            if(downloadingResult === 1){
                this.loadingStatuses[index] = -1;
            } else {
                this.loadingStatuses[index] = 1;
            }
            setTimeout(() => this.loadingStatuses[index] = 0, 2000)
        },
    },
    computed: {
        trackDuration() {
            return this.albumInfo['tracks'].map(track => {
                const minutes = Math.floor(track['duration'] / 60000);
                const seconds = Math.floor(track['duration'] / 1000 % 60).toString().padStart(2, '0');
                return `${minutes}:${seconds}`
            })
        },
        trackTitle() {
            return this.albumInfo['tracks'].map(track => track['title'])
        },

    }
}
</script>