<template>
    <router-link to="" class="h-10 absolute -top-2 left-0 z-50 textButtonColor">
        <div @click="redirectUser()" class="fixed flex items-center responsiveIcon !bg-transparent">
            <img :src="backImage" alt="back">
            <p>Back</p>
        </div>
    </router-link>
</template>

<script>
import backImage from "@/assets/back.svg";

export default {
    name: "BackButton",
    data() {
        return {
            backImage: backImage,
            redirected: false
        }
    },
    methods: {
        redirectUser () {
            if (this.redirected)
                return;
            this.$router.go(-1)
            this.redirected = true;
        }
    }
}
</script>
